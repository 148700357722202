import TrialBanner from "components/common/footer-banners/TrialBanner";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import GlobalLayout from "components/page/GlobalLayout";
import SpeakingSessionAbout from "components/speaking-session/SpeakingSessionAbout";
import SpeakingSessionHero from "components/speaking-session/SpeakingSessionHero";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const SpeakingSessionTemplate = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  useEffect(() => {
    if (frontmatter.draft && window) {
      window.location.href = "/404";
    }
  }, []);

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>{frontmatter.metaTitle}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <SpeakingSessionHero
        image={frontmatter.image}
        logo={frontmatter.logo}
        title={frontmatter.title}
        booth={frontmatter.booth}
        website={frontmatter.eventWebsite}
        startDate={frontmatter.startDate}
        endDate={frontmatter.endDate}
        location={frontmatter.location}
      />
      <SpeakingSessionAbout
        title={frontmatter.sessionTitle}
        startDate={frontmatter.sessionStartDate}
        endDate={frontmatter.sessionEndDate}
        location={frontmatter.sessionLocation}
        description={frontmatter.description}
        learnLines={frontmatter.learnLines}
        speakers={frontmatter.speakers}
      />
      <TrustedPartnerSection button={false} color="var(--nuetral-100)" />
      <IndustryLeadersCards preTitle="CUSTOMER SUCCESS STORIES" />
      <TrialBanner />
    </GlobalLayout>
  );
};

export default SpeakingSessionTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        metaTitle
        metaDescription
        eventWebsite
        startDate
        endDate
        sessionStartDate
        sessionEndDate
        sessionLocation
        location
        booth
        image
        logo
        title
        sessionTitle
        description
        learnLines {
          learnLine
        }
        speakers {
          speakerImage
          role
          company
          speakerDescription
          name
        }
      }
    }
  }
`;
