import {
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import { styled } from "linaria/react";
import moment from "moment";
import React from "react";

import Check from "./img/checkmark.svg";

const AboutSectionInner = styled(SectionInner)`
  gap: 5rem;
`;

const SectionLeft = styled(SectionCol)`
  width: 60%;
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const infoRow = css`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const checkClass = css`
  width: 25px !important;
  height: 25px !important;
  aspect-ratio: 1 !important;
  overflow: visible !important;
  flex-shrink: 0 !important;
`;

const learnLinesClass = css`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const speakerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

const speakerCard = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
`;

const speakerCardHeader = css`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: center;
  * {
    font-size: 14px;
  }
  h6 {
    color: var(--blue-500);
  }
`;

const speakerImage = css`
  width: 90px;
  flex-shrink: 0;
  aspect-ratio: 1;
`;

const speakerInfo = css``;

const SpeakingSessionAbout = ({
  title,
  startDate,
  endDate,
  location,
  description,
  learnLines,
  speakers,
}) => {
  const startDateTime = moment(startDate);
  const endDateTime = moment(endDate);
  const time = ` ${startDateTime.format("h:mm")} - ${endDateTime.format(
    "h:mm A"
  )}`;
  const formattedDate = startDateTime.format("dddd, MMMM Do, YYYY");
  const eventInfo = [
    { title: "Title", info: title },
    { title: "Date", info: formattedDate },
    { title: "Where", info: location },
    { title: "Time", info: time },
  ];
  return (
    <SectionContainer>
      <AboutSectionInner>
        <SectionLeft>
          <TitleSmall>Rhombus Speaking Session</TitleSmall>
          <div>
            {eventInfo.map(item => (
              <div className={infoRow}>
                <h4>{item.title}:</h4>
                <p>{item.info}</p>
              </div>
            ))}
          </div>
          <MainParagraph>{description}</MainParagraph>
          <div className={learnLinesClass}>
            {learnLines.map(item => (
              <div className={infoRow}>
                <img
                  src={Check}
                  alt="check"
                  className={checkClass}
                  placeholder="blurred"
                  objectFit="contain"
                />
                <p>{item.learnLine}</p>
              </div>
            ))}
          </div>
        </SectionLeft>
        <SectionCol className="col" style={{ justifyContent: "flex-start" }}>
          <TitleSmall>Speakers</TitleSmall>
          <div className={speakerContainer}>
            {speakers.map(item => (
              <div className={speakerCard}>
                <div className={speakerCardHeader}>
                  <img
                    src={item.speakerImage}
                    alt={item.name}
                    className={speakerImage}
                  />
                  <div className={speakerInfo}>
                    <h6>{item.name}</h6>
                    <p>{item.role}</p>
                    <b>{item.company}</b>
                  </div>
                </div>
                <MainParagraph>{item.speakerDescription}</MainParagraph>
              </div>
            ))}
          </div>
        </SectionCol>
      </AboutSectionInner>
    </SectionContainer>
  );
};

export default SpeakingSessionAbout;
